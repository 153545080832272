import React, {useState} from "react"
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import MainArea from  '../components/MainArea';
import {Helmet} from 'react-helmet';
import { StaticImage } from "gatsby-plugin-image";


// markup
const RegisterPage = () => {

 

    return (
        <Layout>
            <Helmet>
                <title>Register | Bluetree Group</title>
                <meta name="description" content="Register for the Bluetree Graduate Management Programme" />
            </Helmet> 
            <Banner title="Interested? Apply now" >
                <StaticImage src={"../assets/banners/register-banner.jpg"} alt="Smiling employee" />                                
            </Banner>    
            <MainArea>
                <h2 style={{marginTop: "2em"}}>If you are interested in building your career with us, apply now.</h2>
                <p style={{marginBottom: "2em"}}>Register your details to start the assessment process:</p>
                <p><a className='register-button' href='https://bluetree-group.breezy.hr/p/2bffa9fb74fd01-graduate-management-scheme-autumn-2022'>Register your details</a></p>
                
                
                <div className="light-blue-bar">
                    <h2 style={{ fontWeight: "normal" }}>Our Team Experience department will contact you regarding your application, which will include: </h2>
                    <div className="icon-bar">
                        <div>
                            <img src='/vectors/cv.svg' />
                            <p>CV application</p>                            
                        </div>
                        <div>
                            <img src='/vectors/video.svg' />
                            <p>Short video submission</p>                            
                        </div>
                        <div>
                            <img src='/vectors/laptop.svg' />
                            <p>Online testing</p>                            
                        </div>
                        <div>
                            <img src='/vectors/group.svg' />
                            <p style={{marginBottom: 0}}>Group assessment day</p>                            
                            <p style={{fontSize: "0.8em", marginTop:0, paddingTop: 0, maxWidth: "20em"}}>in February 2023 for cohort 3 shortlisted candidates. This will include paid travel and accommodation as needed for candidates.</p>
                        </div>
                    </div>

                </div>      
                <div className="dark-blue-bar">
                    <h2 style={{ fontWeight: "normal" }}>Key dates for your diary</h2>
                    <div className='spread-text'>
                        <div>
                            <h4>January 2023</h4>
                            <p>Applications for our Summer 2023 programme close</p>
                        </div>
                        <div>
                            <h4>February 2023</h4>
                            <p>Summer Programme assessment day</p>
                        </div>
                        <div>
                            <h4>Summer 2023</h4>
                            <p>Summer Programme commences</p>
                        </div>
                    </div>
                </div>      


            </MainArea>
        </Layout>     
    )
  }
  
  export default RegisterPage
  
